<template>
    <page
        id="teams-list"
        :title="$root.translate('Team:::Teams', {}, 2)"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <div class="page-outer-container">
            <div class="page-inner-container">
                <h3>
                    {{ $root.translate('Team:::Teams', {}, 2) }}
                </h3>
                <data-table
                    id="teams-list"
                    entity-name="Team:::Teams"
                    :columns="visible_columns"
                    :rows="filtered_rows"
                    :search="state.search"
                    :total="state.total"
                    :found="state.found"
                    :current-page="state.page"
                    :total-pages="nb_pages"
                    :can-add="true"
                    :clickable="is_at_least_administrator"
                    :empty-add-button-title="is_regional_admin ? $root.translate('Invite a new Team Captain...') : ''"
                    :add-button-title="is_regional_admin ? $root.translate('Invite a new Team Captain...') : ''"
                    @sort="sort_by"
                    @search="search"
                    @page="goto_page"
                    @add="team_create"
                    @click="maybe_edit_team"
                >
                    <template
                        v-slot:buttons
                    >
                        <export-button
                            v-if="is_at_least_regional_admin"
                            :links="export_links"
                        />
                    </template>
                    <template
                        v-slot:summary="{ row }"
                    >
                        <h6>{{ row.name }}</h6>
                        <p>
                            {{ row.computed_school_name }}<br />
                            <small>{{ row.schoolboard_name }}</small>
                        </p>
                        <div class="text-center">
                            {{ $root.translate("1 trip walked:::{n} trips walked", { n: row.computed_total_trips || 0 }, row.computed_total_trips || 0) }}
                            &bull;
                            <span v-if="total_distance_for_row(row) < 1000">
                                {{ total_distance_for_row(row) | nibnut.number("0,0") }}<span>m</span>
                            </span>
                            <span v-else>
                                {{ total_distance_for_row(row) / 1000 | nibnut.number("0,0.00") }}<span>km</span>
                            </span>
                        </div>
                    </template>
                    <template
                        v-slot:tbody="{ row, field }"
                    >
                        <span v-if="field === 'computed_total_trips'">
                            {{ row.computed_total_trips | nibnut.number("0,0") }}
                        </span>
                        <span v-else-if="field === 'computed_total_distance'">
                            <span v-if="total_distance_for_row(row) < 1000">
                                {{ total_distance_for_row(row) | nibnut.number("0,0") }}<span>m</span>
                            </span>
                            <span v-else>
                                {{ total_distance_for_row(row) / 1000 | nibnut.number("0,0.00") }}<span>km</span>
                            </span>
                        </span>
                        <div v-else-if="field === 'computed_school_name'">
                            {{ row.computed_school_name }}<br />
                            <small>{{ row.schoolboard_name }}</small>
                        </div>
                        <span v-else>{{ row[field] }}</span>
                    </template>
                </data-table>
            </div>
        </div>

        <invite-user-dialog
            v-if="is_regional_admin"
            :show.sync="inviting"
            :user="invitee"
        />
    </page>
</template>

<script type="text/javascript">
import { is_page, is_remote_data_table_source, crud_utilities, is_user_inviter } from "@/nibnut/mixins"

import {
    ExportButton
} from "@/custom/components"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities, is_user_inviter],
    components: {
        ExportButton
    },
    methods: {
        total_distance_for_row (row) {
            return row.computed_total_distance / 100
        },
        team_create () {
            if(this.is_regional_admin) this.prepare_invitation()
            else this.record_create(this.entity)
        },
        maybe_edit_team (team) {
            if(this.is_at_least_administrator) this.record_edit(this.entity, team.uuid)
        }
    },
    computed: {
        fields () {
            return ["fieldset::list"]
        },
        export_links () {
            const links = [
                { title: this.$root.translate("Current Edition Data"), url: this.export_url }
            ]
            if(this.is_at_least_administrator) {
                const all_data_state = {
                    ...this.state,
                    archived: true
                }
                links.push({ title: this.$root.translate("All Data"), url: this.build_export_url(all_data_state) })
            }
            return links
        }
    },
    data () {
        return {
            entity: "team",
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                computed_school_name: { label: "School", sort: null, type: "alpha" },
                computed_total_trips: { label: "Trips", sort: null, type: "numeric" },
                computed_total_distance: { label: "Distance", sort: null, type: "numeric" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
